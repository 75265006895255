import { FacilityImageCarousel } from "@src/appV2/Facilities/components/FacilityImageCarousel";
import { FacilityPhoto } from "@src/appV2/Facilities/types";
interface FacilityPhotosDetails {
  facilityPhotos: FacilityPhoto[];
  facilityStreetImgUrl: string;
  facilityName?: string;
}
export function FacilityPhotos(props: FacilityPhotosDetails) {
  const { facilityPhotos, facilityStreetImgUrl, facilityName } = props;

  if (!facilityPhotos.length && !facilityStreetImgUrl) {
    return null;
  }

  return (
    <>
      {facilityPhotos.length ? (
        <FacilityImageCarousel
          images={facilityPhotos
            // FIXME: this sorting logic should be done on the backendw
            .sort((a, b) => Number(a.index) - Number(b.index))
            .filter((photo) => Boolean(photo.url))
            .map((photo) => ({ url: photo.url as string, label: photo.name }))}
        />
      ) : (
        <div className="facility-photo-container">
          <img
            alt={facilityName}
            className="facility-street-map-view"
            data-testid="facility-photo-map-view"
            src={facilityStreetImgUrl}
          />
        </div>
      )}
    </>
  );
}
