export const HCP_ACTIONS = {
  TIME_SLOT_CLICK: "TIME_SLOT_CLICK",
  MARK_INTEREST: "MARK_INTEREST",
  SELF_CLAIM: "SELF_CLAIM",
};

export const MISSING_DOCS_TRIGGER = {
  MISSING_DOCUMENTS: "MISSING_DOCUMENTS",
  BOOKING_ATTEMPT: "BOOKING_ATTEMPT",
};

export interface ISharedShiftSlot {
  sharedShiftStart: string;
  sharedShiftEnd: string;
  sharedShiftName: string;
  sharedFacilityId: string;
}

export const TIME_FORMAT = "h:mm a";
