import { formatLongDate } from "@clipboard-health/date-time";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent } from "@mui/material";
import { useAgentPreferences } from "@src/appV2/Agents/api/useAgentPreferences";
import { RootPaths } from "@src/appV2/App/paths";
import { AppBarHeader, BackButtonLink, FullScreenDialog, PageWithHeader } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useAgentShifts } from "@src/appV2/OpenShifts/api/useAgentShifts";
import { ShiftSlotsRequest, useGetShiftSlots } from "@src/appV2/OpenShifts/api/useGetShiftSlots";
import { useOpenShifts } from "@src/appV2/OpenShifts/api/useOpenShifts";
import { OpenShiftDayView } from "@src/appV2/OpenShifts/DayView";
import { generateShiftSlots } from "@src/appV2/OpenShifts/utils/generateShiftSlots";
import { Shift, ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES, SEARCH_MODE } from "@src/constants";
import { Shift as ShiftV1 } from "@src/lib/interface/src";
import { format, parseISO } from "date-fns";
import { groupBy } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { ShiftListPage } from "./shiftList";

export function OpenShiftDayViewPage() {
  const { date: urlDate } = useParams<{ date: string }>();
  const parsedDate = z.date().parse(parseISO(urlDate));

  const [selectedShiftPeriod, setSelectedShiftPeriod] = useState<ShiftPeriod>();

  const shiftListModalState = useModalState();

  const { filters } = useAgentPreferences();

  const distancePreference = filters?.distance ?? DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES;
  const qualificationPreference = filters?.license ?? "";

  const worker = useDefinedWorker();

  const {
    data: openShiftsData,
    isLoading: openShiftsIsLoading,
    isSuccess: openShiftsIsSuccess,
    refetch: refetchOpenShifts,
  } = useOpenShifts(
    {
      dateFilter: {
        start: format(parsedDate, "yyyy-MM-dd"),
        end: format(parsedDate, "yyyy-MM-dd"),
      },
      coordinates: worker.geoLocation?.coordinates ?? [0, 0],
      distance: worker.preference.distance ?? 150,
      qualification: worker.preference.qualification ?? "",
      specialities: {
        hasSedationExperience: worker.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: worker.specialities?.hasTrayAssemblyExperience ?? false,
      },
    },
    {
      enabled:
        isDefined(filters?.distance) &&
        isDefined(filters?.license) &&
        worker.geoLocation?.coordinates.length === 2,
    }
  );

  const {
    data: agentShiftsData,
    isLoading: agentShiftsIsLoading,
    isSuccess: agentShiftsIsSuccess,
    refetch: refetchAgentShifts,
  } = useAgentShifts({
    startDate: parsedDate,
    endDate: parsedDate,
    tmz: worker.tmz,
    groupByDate: false,
  });

  const openShiftsGroupedByShiftPeriod = openShiftsIsSuccess
    ? groupBy(
        openShiftsData?.openAgentShifts.filter((shift) => !shift.filtered),
        (shift: Shift) => {
          return shift.name;
        }
      )
    : { [ShiftPeriod.AM]: [], [ShiftPeriod.PM]: [], [ShiftPeriod.NOC]: [] };

  const agentShiftsGroupedByDateAndShiftPeriod = agentShiftsIsSuccess
    ? groupBy(agentShiftsData.agentShifts, (shift: Shift): string => {
        return shift.name as string;
      })
    : {};

  const shiftSlots: ShiftSlotsRequest["workplaceSlots"] = generateShiftSlots(
    agentShiftsData?.agentShifts ?? [],
    openShiftsData?.openAgentShifts ?? []
  );

  const { data: shiftSlotsData } = useGetShiftSlots(
    {
      workplaceSlots: shiftSlots,
    },
    {
      enabled: openShiftsIsSuccess && agentShiftsIsSuccess && shiftSlots.length > 0,
    }
  );

  return (
    <>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={formatLongDate(parsedDate)}
            leftCta={<BackButtonLink defaultBackTo={RootPaths.HOME} />}
          />
        }
        onRefresh={async () => {
          await Promise.all([refetchOpenShifts, refetchAgentShifts]);
        }}
      >
        <OpenShiftDayView
          date={urlDate}
          onShiftPeriodClick={(props) => {
            const { shiftPeriod, isActive } = props;

            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_DAY_VIEW_SHIFT_PERIOD_SELECTED, {
              shiftPeriod,
              isActive,
            });

            if (isActive) {
              setSelectedShiftPeriod(shiftPeriod);
              shiftListModalState.openModal();
            }
          }}
        />
      </PageWithHeader>

      {isDefined(selectedShiftPeriod) ? (
        <FullScreenDialog modalState={shiftListModalState}>
          <DialogContent sx={{ padding: 0 }}>
            <ShiftListPage
              onClose={() => {
                setSelectedShiftPeriod(undefined);
                shiftListModalState.closeModal();
              }}
              day={urlDate}
              shiftName={selectedShiftPeriod}
              shifts={
                isDefined(selectedShiftPeriod)
                  ? {
                      open:
                        (openShiftsGroupedByShiftPeriod[selectedShiftPeriod] as ShiftV1[]) ?? [],
                      assigned:
                        (agentShiftsGroupedByDateAndShiftPeriod[
                          selectedShiftPeriod
                        ] as ShiftV1[]) ?? [],
                    }
                  : { open: [], assigned: [] }
              }
              getOpenAndMyShifts={() => {
                refetchOpenShifts();
                refetchAgentShifts();
              }}
              loadingShiftList={agentShiftsIsLoading ?? openShiftsIsLoading}
              searchMode={SEARCH_MODE.TIME_SLOT}
              distancePreference={distancePreference}
              qualificationPreference={qualificationPreference}
              filterDays={1}
              shiftSlots={shiftSlotsData}
              updateShiftFields={() => {
                refetchOpenShifts();
                refetchAgentShifts();
              }}
            />
          </DialogContent>
        </FullScreenDialog>
      ) : null}
    </>
  );
}
