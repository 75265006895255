import "./style.scss";
import { isDefined } from "@clipboard-health/util-ts";
import { IShiftSlot } from "@src/app/components/workWithFriends/interface";
import { BookabilityAttributes } from "@src/appV2/OpenShifts/ShiftAction";
import { SEARCH_MODE } from "@src/constants";
import { Shift } from "@src/lib/interface";
import { FC } from "react";

import { allowFacilityDetailsSearchModes } from "./constants";
import { HcfProfileSource } from "./model";
import { ShiftListGroupState } from "../model";
import { ShiftItem } from "../shiftItem";

type FacilityShiftItemProps = {
  day: string;
  shift: Shift;
  shifts: ShiftListGroupState;
  getFacilityShifts: () => Promise<any>;
  setClickedInterestInShift?: (value: boolean) => void;
  isHcpInstantPayEnabled?: boolean;
  searchMode?: SEARCH_MODE;
  availableShiftIds?: string[];
  filterDistance?: number;
  filterDays?: number;
  hcfProfileSource?: HcfProfileSource;
  shiftSlots?: IShiftSlot[];
  allowedAgentReq?: string[];
  onFacilityDetailsClick?: (
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard?: boolean
  ) => void;
  shiftBookability: BookabilityAttributes;
  isShiftBookabilityLoading: boolean;
};

const FacilityShiftItem: FC<FacilityShiftItemProps> = ({
  shift,
  getFacilityShifts,
  setClickedInterestInShift,
  isHcpInstantPayEnabled,
  searchMode,
  availableShiftIds,
  filterDistance,
  filterDays,
  hcfProfileSource,
  shiftSlots,
  allowedAgentReq,
  onFacilityDetailsClick,
  shiftBookability,
  isShiftBookabilityLoading,
}) => {
  const facilityDetailsPageIsEnabled =
    isDefined(searchMode) && allowFacilityDetailsSearchModes.includes(searchMode);

  return (
    <ShiftItem
      shift={shift}
      updateShiftList={getFacilityShifts}
      setClickedInterestInShift={setClickedInterestInShift}
      isHcpInstantPayEnabled={isHcpInstantPayEnabled}
      showFacilityDetails={facilityDetailsPageIsEnabled}
      availableShiftIds={availableShiftIds}
      searchMode={searchMode}
      filterDistance={filterDistance}
      filterDays={filterDays}
      hcfProfileSource={hcfProfileSource}
      shiftSlots={shiftSlots}
      allowedAgentReq={allowedAgentReq}
      onFacilityDetailsClick={onFacilityDetailsClick}
      shiftBookability={shiftBookability}
      isShiftBookabilityLoading={isShiftBookabilityLoading}
      isForFacilityOpenShift={true}
    />
  );
};
export { FacilityShiftItem };
