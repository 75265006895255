import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useMemo } from "react";

export const useShiftReqTag = () => {
  const ldFlags = useCbhFlags();
  const openShiftAgentReqFF = ldFlags[CbhFeatureFlag.OPEN_SHIFT_CARD_AGENT_REQ];

  const allowedAgentReq = useMemo(() => {
    if (openShiftAgentReqFF?.global) {
      return ["all"];
    } else {
      const listAllowedReq = Object.keys(openShiftAgentReqFF?.agentReq || {}).filter(
        (type) => openShiftAgentReqFF?.agentReq?.[type]
      );
      return listAllowedReq;
    }
  }, [openShiftAgentReqFF]);

  return { allowedAgentReq };
};
