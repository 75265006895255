import { Shift } from "@src/appV2/Shifts/Shift/types";
import { StreakAlert } from "@src/appV2/Streaks/components/StreakAlert";
import { Streak } from "@src/appV2/Streaks/types";
import { checkShiftHasStreakPayBoost } from "@src/appV2/Streaks/utils";

interface FacilityPageStreakBannerProps {
  facilityShifts: Shift[];
  hcpStreaksResponseData: Streak[];
}

export function FacilityPageStreakBanner(props: FacilityPageStreakBannerProps) {
  const { facilityShifts, hcpStreaksResponseData } = props;

  const hasOpenShiftsWithNoStreak =
    hcpStreaksResponseData.length > 0 &&
    !facilityShifts.every((openShift) =>
      checkShiftHasStreakPayBoost({
        hcpStreaks: hcpStreaksResponseData,
        shiftStart: openShift.start,
      })
    );
  return hasOpenShiftsWithNoStreak ? (
    <StreakAlert>Lock in your Streak rewards by booking shifts in advance!</StreakAlert>
  ) : null;
}
